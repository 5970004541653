

























































































































import Vue from 'vue';
import { types } from '@truck-parking/tp-api';

export default Vue.extend({
  name: 'History',
  data() {
    return {
      loading: true,
      parkingEvents: [] as Array<types.ParkingEvent>,
    };
  },
  mounted() {
    this.$api.getParkingEvents().then(({ body: pe }) => {
      this.parkingEvents = pe.parkingEvents;
      this.loading = false;
    });
  },
  methods: {
    /** Return date as dd/mm/yyyy */
    dateToString(date: Date): string {
      const day = date.getDate().toString();
      const year = date.getFullYear().toString();
      const month = (date.getMonth() + 1).toString();
      return `${day}/${month}/${year}`;
    },
    /** Return time from date as hh/mm */
    timeToString(time: Date): string {
      const hours = time.getHours().toString();
      const mins = time.getMinutes().toString();
      return `${(`0${hours}`).slice(-2)}:${(`0${mins}`).slice(-2)}`;
    },
    /** return duration in format "1 h 59 min" */
    getDuration(parkingEvent: types.ParkingEvent): string {
      const start = new Date(parkingEvent.startDateTime);
      const end = new Date(parkingEvent.endDateTime);
      const diff = Math.floor((end.getTime() - start.getTime()) / 1000 / 60);
      const hours = Math.floor(diff / 60);
      const mins = diff % 60;
      return `${hours} h ${mins} min`;
    },
    /** return duration in seconds */
    getDurationInSeconds(parkingEvent: types.ParkingEvent): number {
      const start = new Date(parkingEvent.startDateTime);
      const end = new Date(parkingEvent.endDateTime);
      const diff = Math.floor((end.getTime() - start.getTime()) / 1000);
      return diff;
    },
    /** convert seconds to format "1 h 59 min" */
    secondsToDurationString(seconds: number): string {
      const diff = Math.floor(seconds / 60);
      const hours = Math.floor(diff / 60);
      const mins = diff % 60;
      return `${hours} h ${mins} min`;
    },
  },
});
